.dashboardContent {
    width: 100%;
    height: 100%;
}

.dashboard-table-wrapper_ {
    background: #FFFFFF;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.dashboard-table-wrapper_ h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    padding: 10px 0 0 10px;
}

.css-17jjc08-MuiDataGrid-footerContainer {
    height: 35px !important;
    min-height: 35px !important;
}

.MuiDataGrid-root {
    border: 0 !important;
}

.MuiDataGrid-root>div:nth-child(2)>div:nth-child(1) {
    background: #F7F7F7;
    mix-blend-mode: normal;
    min-height: 40px !important;
    max-height: 40px !important;

}

.MuiDataGrid-root>div:nth-child(2)>div:nth-child(2)>div>div {
    height: auto !important;
}

.MuiDataGrid-root>div:nth-child(2)>div:nth-child(2)>div>div>div>div,
.MuiDataGrid-root>div:nth-child(2)>div:nth-child(2)>div>div>div>div>div {
    min-height: 40px !important;
    max-height: 40px !important;
}

.MuiDataGrid-root>div:nth-child(2)>div:nth-child(1)>div>div {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 15px;
    letter-spacing: 0.01em !important;
    color: #00446B;
}

.MuiDataGrid-root>div:first-child div>div {
    justify-content: flex-end;
}

.MuiDataGrid-root>div:first-child div>div>button {
    color: white !important;
    background: #29abe2 !important;
}

.MuiDataGrid-cellContent {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    letter-spacing: 0px;
    color: black !important;
}