.header-admin {
    height: 55px;
    width: 100%;
    background: white;
    color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
}

.header-admin>nav {
    padding: 0 8px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.nav-bar-child1 {
    display: flex;
    align-items: center;
}

.nav-bar-hamburger {
    margin-right: 8px;
    cursor: pointer;
    display: block;
    width: 20px;
}

.nav-bar-hamburger img {
    width: 100%;
}

.input-header-admin input {
    height: 35px;
    width: 160px;
    border: 0;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: #a5a5a5;
    padding: 0 10px 0 27px;
    position: relative;
}

.input-header-admin img {
    position: absolute;
    transform: translate(-183px, 10px);
    cursor: pointer;
}

.header-admin .noti-icon {
    margin-right: 0;
    margin-left: 7px;
}

.header-admin .noti-icon img {
    cursor: pointer;
}

.nav-bar .nav-child-2 {
    display: flex;
    align-items: center;
}

.nav-buttons {
    display: block;
}

.nav-buttons button {
    width: 120px;
    height: 30px;
    border: 0;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
}

@media screen and (max-width:419px) {
    .input-header-admin img {
        transform: translate(-155px, 10px);
    }
}

@media screen and (max-width:610px) {
    .nav-buttons {
        display: none;
    }
}

@media screen and (min-width:420px) {
    .header-admin>nav {
        padding: 0 27px !important;
    }

    .nav-bar-hamburger {
        width: 25px;
        margin-right: 15px;
    }

    .input-header-admin input {
        width: 190px;
    }

    .header-admin .noti-icon {
        margin-left: 17px;
    }
}

@media screen and (min-width:901px) {
    .nav-bar-hamburger {
        display: none;
    }
}